import React from 'react'

export const Tick = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={20} height={20} xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20"  xmlSpace="preserve">
    <path fill="#00B6ED" d="M8.29,14.64c-0.38,0-0.76-0.15-1.06-0.43l-2.87-2.82c-0.59-0.58-0.6-1.53-0.01-2.12 c0.58-0.59,1.53-0.6,2.12-0.02l1.81,1.78l5.23-5.23c0.59-0.59,1.54-0.59,2.12,0s0.59,1.54,0,2.12L9.35,14.2 C9.06,14.5,8.67,14.64,8.29,14.64z"/>
  </svg>
)

export const TickCircle = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 29.5 29.1">
    <defs>
    </defs>
    <g>
      <g>
        <circle fill="#17b5ea" cx="14.8" cy="14.5" r="14"/>
      </g>
      <g>
        <path fill="#fff" d="M12.5,19.2c-.3,0-.5,0-.7-.3l-3-3c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l2.3,2.3,6.7-6.6c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-7.4,7.3c-.2.2-.4.3-.7.3Z"/>
      </g>
    </g>
  </svg>

)


